import React, { useEffect, useState } from "react";
import ChartistGraph from "react-chartist";
import Spinner from 'react-bootstrap/Spinner';
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { sendRequest } from "services/GeneralServices";

function Dashboard() {
  const [usersCount, setUserCount] = useState(0);
  const [approvedFarmers, setApprovedFarmers] = useState(0);
  const [pendingFarmers, setPendingFarmers] = useState(0);
  const [orders, setOrders] = useState(0);
  const [statsLoading, setStatsLoader] = useState(false);
  useEffect(() => {
    getStats();
  }, []);
  const getStats = async () => {
    setStatsLoader(true)
    const users = await sendRequest("users", "GET", null, true);
    if (users) {
      if (users.status === 200 || users.status === 201) {
        console.log("orders", users);
        console.log("users count", users?.data?.length);
        setUserCount(users?.data?.length);
      } else {
        window.alert(users?.msg);
      }
    } else {
      // setError("An error occurred while pulling products");
      // window.alert("An error occurred while pulling products");
    }

    //get farmers
    const farmers = await sendRequest("farmers", "GET", null, true);
    if (farmers) {
      if (farmers.status === 200 || farmers.status === 201) {
        const activeFarmers = farmers?.data.filter(
          (farmer) => Boolean(farmer?.user?.isVerified) === true
        );
        const pendingFarmers = farmers?.data.filter(
          (farmer) => Boolean(farmer?.user?.isVerified) != true
        );
        console.log(">>", farmers);
        setPendingFarmers(pendingFarmers?.length);
        setApprovedFarmers(activeFarmers?.length);
      } else {
        window.alert(farmers?.msg);
      }
    } else {
      // setError("An error occurred while pulling products");
      // window.alert("An error occurred while pulling products");
    }
    //get orders
    const orders = await sendRequest('orders','GET');
    if (orders) {
      if (orders.status === 200 || orders.status === 201) {
        console.log(">>", orders);
        setOrders(orders?.data?.length);
      } else {
        window.alert(orders?.msg);
      }
    } else {
      // setError("An error occurred while pulling products");
      // window.alert("An error occurred while pulling products");
    }
    setStatsLoader(false)
  };


  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Overall system users</p>
                      {statsLoading ? <Spinner size="sm" variant="success" animation="grow" /> : <Card.Title as="h4">{usersCount}</Card.Title>}
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-redo mr-1"></i>
                  Update Now
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-light-3 text-success"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Revenue</p>
                      <Card.Title as="h4">R 1,345</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt mr-1"></i>
                  Last day
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-vector text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">AF/NAF </p>
                      <Card.Title as="h4">
                      {statsLoading ? <Spinner size="sm" variant="success" animation="grow" /> : `${approvedFarmers} / ${pendingFarmers}`}
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-clock-o mr-1"></i>
                  Approved Farmer/Not Approved
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-favourite-28 text-primary"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Current Orders</p>
                      <Card.Title as="h4"> {statsLoading ? <Spinner size="sm" variant="success" animation="grow" /> :+orders}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-redo mr-1"></i>
                  Update now
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Order Volume</Card.Title>
                <p className="card-category">24 Hours performance</p>
              </Card.Header>
              <Card.Body>
                <div className="ct-chart" id="chartHours">
                  <ChartistGraph
                    data={{
                      labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                      series: [[287, 385, 490, 492, 554, 586, 698]],
                    }}
                    type="Line"
                    options={{
                      low: 0,
                      high: 800,
                      showArea: false,
                      height: "245px",
                      axisX: {
                        showGrid: false,
                      },
                      lineSmooth: true,
                      showLine: true,
                      showPoint: true,
                      fullWidth: true,
                      chartPadding: {
                        right: 50,
                      },
                    }}
                    responsiveOptions={[
                      [
                        "screen and (max-width: 640px)",
                        {
                          axisX: {
                            labelInterpolationFnc: function (value) {
                              return value[0];
                            },
                          },
                        },
                      ],
                    ]}
                  />
                </div>
              </Card.Body>
              <Card.Footer>
                {/* <div className="legend">
                  <i className="fas fa-circle text-info"></i>
                  Open <i className="fas fa-circle text-danger"></i>
                  Click <i className="fas fa-circle text-warning"></i>
                  Click Second Time
                </div> */}
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-history"></i>
                  Updated 3 minutes ago
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Provincial Farmers</Card.Title>
                <p className="card-category">overall farmers </p>
              </Card.Header>
              <Card.Body>
                <div
                  className="ct-chart ct-perfect-fourth"
                  id="chartPreferences"
                >
                  <ChartistGraph
                    data={{
                      labels: [
                        "GP",
                        "MP",
                        "KZN",
                        "NW",
                        "LM",
                        "WC",
                        "FS",
                        "EC",
                        "NC",
                      ],
                      series: [10, 20, 5, 10, 15, 10, 10, 10, 10],
                    }}
                    type="Pie"
                  />
                </div>
                {/* <div className="legend">
                  <i className="fas fa-circle text-info"></i>
                  Open <i className="fas fa-circle text-danger"></i>
                  Bounce <i className="fas fa-circle text-warning"></i>
                  Unsubscribe

                </div> */}
                <hr></hr>
                <div className="stats">
                  <i className="far fa-clock"></i>
                  Campaign sent 2 days ago
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">2023 accounts management</Card.Title>
                <p className="card-category">
                  Stats showing approved user accounts vs pending accounts
                </p>
              </Card.Header>
              <Card.Body>
                <div className="ct-chart" id="chartActivity">
                  <ChartistGraph
                    data={{
                      labels: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "Mai",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                      ],
                      series: [
                        [
                          542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756,
                          895,
                        ],
                        [
                          412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636,
                          695,
                        ],
                      ],
                    }}
                    type="Bar"
                    options={{
                      seriesBarDistance: 10,
                      axisX: {
                        showGrid: false,
                      },
                      height: "245px",
                    }}
                    responsiveOptions={[
                      [
                        "screen and (max-width: 640px)",
                        {
                          seriesBarDistance: 5,
                          axisX: {
                            labelInterpolationFnc: function (value) {
                              return value[0];
                            },
                          },
                        },
                      ],
                    ]}
                  />
                </div>
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  <i className="fas fa-circle text-info"></i>
                  Approved Accounts{" "}
                  <i className="fas fa-circle text-danger"></i>
                  Not approved
                </div>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-check"></i>
                  Data information certified
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
