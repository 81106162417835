
import React, { Component } from "react";

import { Dropdown, Badge, Button, Form } from "react-bootstrap";



function FixedPlugin({
  hasImage,
  setHasImage,
  color,
  setColor,
  image,
  setImage
}) {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     classes: "dropdown show-dropdown open",
  //     bg_checked: true,
  //     bgImage: this.props.bgImage,
  //   };
  // }
  // handleClick = () => {
  //   this.props.handleFixedClick();
  // };
  // onChangeClick = () => {
  //   this.props.handleHasImage(!this.state.bg_checked);
  //   this.setState({ bg_checked: !this.state.bg_checked });
  // };
  return (
<></>
  );
}

export default FixedPlugin;
