import React, { Component } from 'react'
import { Table, Button } from 'reactstrap';
import ModalForm from './Modal';
import { sendRequest } from 'services/GeneralServices';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class DataTable extends Component {

  deleteItem = async(id) => {
    let confirmDelete = window.confirm('Are you certain you wish to delete the selected user?')
    if(confirmDelete){
        this.props.deleteItemFromState(id)
        const res = await sendRequest(`users/${id}`,"DELETE",null,true);
        if (res) {
          if (res.status === 200 || res.status === 201) {
            //deleted
          } else {
            window.alert(res?.msg);
          }
        } else {
          console.log("Something went wrong while deleting a user",res?.msg);
        }
    }

  }

  render() {
    const items = this.props.items.map(item => {
        console.log("this item",item)
      return (
        <tr key={item?.id}>
          <td>{item?.user?.firstname === undefined ?item?.firstname : item?.user?.firstname }</td>
          <td>{item?.user?.lastname === undefined ? item?.lastname : item?.user?.lastname   }</td>
          <td>{item?.user?.email === undefined ?  item?.email : item?.user?.email }</td>
          <td>{item?.user?.phone === undefined ? item?.phone : item?.user?.phone  }</td>
          {/* <td>{item?.user?.username === undefined ?  item?.username : item?.user?.username}</td> */}
          <td>{item?.user?.isVerified ? 'verified' : 'not verified'}</td>
          <td>
            <div style={{display:'flex', flexDirection:'row',justifyContent:'space-around'}}>
              <ModalForm buttonLabel="Edit" item={item} updateState={this.props.updateState}/>
              {' '}
              <div onClick={this.toggle} >
        <FontAwesomeIcon color="grey" icon="trash-can" onClick={() => this.deleteItem(item?.user?._id)} />
      </div>
              {/* <Button color="danger" onClick={() => this.deleteItem(item?.user?._id)}>Del</Button> */}
            </div>
          </td>
        </tr>
        )
      })

    return (
      <Table responsive hover>
        <thead>
          <tr>
            <th>First</th>
            <th>Last</th>
            <th>Email</th>
            <th>Phone</th>
            {/* <th>UserName</th> */}
            <th>Is Verified</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {items}
        </tbody>
      </Table>
    )
  }
}

export default DataTable