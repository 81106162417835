import React from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { sendRequest } from "services/GeneralServices";
import Spinner from "react-bootstrap/Spinner";
class AddEditForm extends React.Component {
  state = {
    _id: 0,
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    phone: "",
    type: "",
    username: "",
    userId:"",
    isVerified: false,
    businessName: "",
    businessType: "",
    companySize: "",
    funding: null,
    hasPackHouse: false,
    isLookingForFunding: false,
    land: null,
    registrationNumber: "",
    revenueSize: "",
    isLoading:false
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onApproveUser = async(e) => {
    this.setState({ [e.target.name]: e.target.value });
    const res = await sendRequest(`verify-user/${this.state.userId}`,"PATCH",null,true);
    if (res) {
      if (res.status === 200 || res.status === 201) {
        //this.props.deleteItemFromState(id);
      } else {
        console.log(res?.msg);
      }
    } else {
      console.log("Something went wrong while approving the user");
    }

  }
  submitFormAdd = (e) => {
    e.preventDefault();
    this.props.addItemToState({
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      phone: this.state.phone,
      isVerified: this.state.isVerified,
      type: this.state.type,
    });
    this.props.toggle();
  };

  submitFormEdit = async(e) => {
    e.preventDefault();
    this.setState({isLoading:true});
    this.props.updateState({
      _id: this.state._id,
      user:{
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        username: this.state.username,
        email: this.state.email,
        phone: this.state.phone,
        isVerified: this.state.isVerified,
      },
      type: this.state.type,
      businessName: this.state.businessName,
      businessType: this.state.businessType,
      companySize: this.state.companySize,
      funding: this.state.funding,
      hasPackHouse: this.state.hasPackHouse,
      isLookingForFunding: this.state.isLookingForFunding,
      land: this.state.land,
      registrationNumber: this.state.registrationNumber,
      revenueSize: this.state.revenueSize,
    });
    const user = await sendRequest(`/users/${this.state.userId}`,'PATCH',{
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      location: "",
      province: "",
      phone: this.state.phone,
      email: this.state.email
    },true);
    if (user) {
      if (user.status === 200 || user.status === 201) {
        console.log("user updated",user)
      } else {
        window.alert(user?.msg);
      }
    } else {
      //handled by general service
      //window.alert("An error occurred while pulling orders");
    }
    this.setState({isLoading:false});
    this.props.toggle();
  };

  componentDidMount() {
    // if item exists, populate the state with proper data
    if (this.props.item) {
      const {
        _id,
        user,
        type,
        businessName,
        businessType,
        companySize,
        funding,
        hasPackHouse,
        isLookingForFunding,
        land,
        registrationNumber,
        revenueSize,
      } = this.props.item;
      this.setState({
        _id,
        firstname: user?.firstname,
        lastname: user?.lastname,
        username: user?.username,
        email: user?.email,
        userId:user?._id,
        phone: user?.phone,
        isVerified: user?.isVerified,
        type: type,
        businessName,
        businessType,
        companySize,
        funding,
        hasPackHouse,
        isLookingForFunding,
        land,
        registrationNumber,
        revenueSize,
      });
    }
  }

  render() {
    return (
      <>
       {this.state.isLoading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Submitting...</span>
          </Spinner>
          <p>This might take few seconds</p>
        </div>
      ) :
      (<Form
        onSubmit={this.props.item ? this.submitFormEdit : this.submitFormAdd}
      >
        <FormGroup>
          <Label for="first">First Name</Label>
          <Input
            type="text"
            name="firstname"
            id="firstname"
            onChange={this.onChange}
            value={this.state.firstname === null ? "" : this.state.firstname}
          />
        </FormGroup>
        <FormGroup>
          <Label for="lastname">Last Name</Label>
          <Input
            type="text"
            name="lastname"
            id="lastname"
            onChange={this.onChange}
            value={this.state.lastname === null ? "" : this.state.lastname}
          />
        </FormGroup>
        <FormGroup>
          <Label for="lastname">User Name</Label>
          <Input
            type="text"
            name="username"
            id="username"
            onChange={this.onChange}
            value={this.state.username === null ? "" : this.state.username}
          />
        </FormGroup>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            type="email"
            name="email"
            id="email"
            onChange={this.onChange}
            value={this.state.email === null ? "" : this.state.email}
          />
        </FormGroup>
        <FormGroup>
          <Label for="phone">Phone</Label>
          <Input
            type="text"
            name="phone"
            id="phone"
            onChange={this.onChange}
            value={this.state.phone === null ? "" : this.state.phone}
            placeholder="ex. 555-555-5555"
          />
        </FormGroup>
        <h6 class="text-uppercase">Deactivate/Activate Farmer Profile</h6>
        <hr class="solid" />
        <FormGroup>
          <Label for="location">Is the Farmer Account Active ? </Label>
          <Input
          disabled
            type="text"
            name="isVerified"
            id="isVerified"
            onChange={this.onChange}
            value={this.state.isVerified? this.state.isVerified : false}
          />
        </FormGroup>
        <div>
          <label>
            Deactivate / Active Farmer Profile:
            <select
              name="isVerified"
              style={{
                width: "100px",
                padding: 10,
                borderRadius: 10,
                width: "450px",
                margin: "10px 0px",
              }}
              value={
                this.state.isVerified? this.state.isVerified : false
              }
              onChange={this.onApproveUser}
            >
              <option value="">-- Select --</option>
              <option value={true}>Activate</option>
              <option value={false}>Deactivate</option>
            </select>
          </label>
        </div>

        <h6 class="text-uppercase">Farmer Profile</h6>
        <hr class="solid" />
        <Label style={{color:'grey',fontSize:12,}}>🛈 The farmer profile is approved, It can only be edited by the farmer</Label>
        <FormGroup>
          <Label for="hobby">Business Name</Label>
          <Input
            disabled
            type="text"
            name="businessName"
            id="businessName"
            onChange={this.onChange}
            value={this.state.businessName}
          />
        </FormGroup>
        <FormGroup>
          <Label for="hobby">Business Type</Label>
          <Input
            disabled
            type="text"
            name="businessType"
            id="businessType"
            onChange={this.onChange}
            value={this.state.businessType}
          />
        </FormGroup>
        <FormGroup>
          <Label for="hobby">Company Size</Label>
          <Input
            disabled
            type="text"
            name="companySize"
            id="companySize"
            onChange={this.onChange}
            value={this.state.companySize}
          />
        </FormGroup>
        <FormGroup>
          <Label for="hobby">Has PackHouse</Label>
          <Input
            disabled
            type="text"
            name="hasPackHouse"
            id="hasPackHouse"
            onChange={this.onChange}
            value={this.state.hasPackHouse}
          />
        </FormGroup>
        <FormGroup>
          <Label for="hobby">Is Looking for Funding</Label>
          <Input
            disabled
            type="text"
            name="isLookingForFunding"
            id="isLookingForFunding"
            onChange={this.onChange}
            value={this.state.isLookingForFunding}
          />
        </FormGroup>
        <FormGroup>
          <Label for="hobby">Registration Number</Label>
          <Input
            disabled
            type="text"
            name="registrationNumber"
            id="registrationNumber"
            onChange={this.onChange}
            value={this.state.registrationNumber}
          />
        </FormGroup>
        <FormGroup>
          <Label for="hobby">Revenue Size</Label>
          <Input
            disabled
            type="text"
            name="revenueSize"
            id="revenueSize"
            onChange={this.onChange}
            value={this.state.revenueSize}
          />
        </FormGroup>
        <h6 class="text-uppercase">Land Details</h6>
        <hr class="solid" />
        <FormGroup>
          <Label for="hobby">Ownership</Label>
          <Input
            disabled
            type="text"
            name="land"
            id="land"
            onChange={this.onChange}
            value={this.state.land?.ownership}
          />
        </FormGroup>
        <FormGroup>
          <Label for="hobby">Land Size</Label>
          <Input
            disabled
            type="text"
            name="land"
            id="land"
            onChange={this.onChange}
            value={this.state.land?.size}
          />
        </FormGroup>
        <FormGroup>
          <Label for="hobby">Total Ploughed</Label>
          <Input
            disabled
            type="text"
            name="land"
            id="land"
            onChange={this.onChange}
            value={this.state.land?.totalPloughed}
          />
        </FormGroup>
        <h6 class="text-uppercase">Funding Details</h6>
        <hr class="solid" />
        <FormGroup>
          <Label for="hobby">Bank</Label>
          <Input
            disabled
            type="text"
            name="funding"
            id="funding"
            onChange={this.onChange}
            value={this.state.funding?.bank}
          />
        </FormGroup>
        <FormGroup>
          <Label for="hobby">Has Crop Insurance</Label>
          <Input
            disabled
            type="text"
            name="funding"
            id="funding"
            onChange={this.onChange}
            value={this.state.funding?.hasCropInsurance}
          />
        </FormGroup>
        <FormGroup>
          <Label for="hobby">Source</Label>
          <Input
            disabled
            type="text"
            name="funding"
            id="funding"
            onChange={this.onChange}
            value={this.state.funding?.source}
          />
        </FormGroup>
        <Button>Submit</Button>
      </Form>)}
      </>
    );
  }
}

export default AddEditForm;
