import React, { Component } from "react";
import { Table, Button } from "reactstrap";
import ModalForm from "./Modal";


class DataTable extends Component {
  render() {
    const items = this.props.items.map((item) => {
      const date = new Date(item.datePurchased);
      const formattedDate = date.toLocaleString();
      return (
        <tr key={item.id}>
          <th scope="row">Order Number {item.orderNumber}</th>
          <td>{formattedDate}</td>
          <td>R {item.amount}</td>
          <td>{item.status}</td>
          <td>{item.driver === undefined ? 'Not yet assigned' : item.driver}</td>
          <td>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <ModalForm
                buttonLabel="View"
                item={item}
                updateState={this.props.updateState}
              />{" "}
              <ModalForm
                buttonLabel="Assign"
                item={item}
                updateState={this.props.updateState}
              />{" "}
            </div>
          </td>
        </tr>
      );
    });

    return (
      <Table responsive hover>
        <thead>
          <tr>
            <th>Order Number</th>
            <th>Order Date</th>
            <th>Total Amount</th>
            <th>Status</th>
            <th>Driver</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{items}</tbody>
      </Table>
    );
  }
}

export default DataTable;
