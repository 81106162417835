import React from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import GeneralServices from "services/GeneralServices";
import { sendRequest } from "services/GeneralServices";
import Spinner from "react-bootstrap/Spinner";
class AssignForm extends React.Component {
  state = {
    amount:'',
    buyer_id:'',
    datePurchased:'',
    farmers:[],
    orderNumber:'',
    products:[],
    status:'',
    _id:'',
    buyerName:'Sainty',
    buyerContacts:'0767036727',
    driver: "",
    isLoading:false
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  assignDriver = async (e) => {
    
    e.preventDefault();
    this.setState({isLoading:true});
    this.props.updateState({
      _id: this.state._id,
      amount: this.state.amount,
      buyer_id: this.state.buyer_id,
      datePurchased: this.state.datePurchased,
      orderNumber: this.state.orderNumber,
      status: this.state.status,
      address: this.state.address,
      driver: this.state.driver,
      products:this.state.products,
      farmers: this.state.farmers
    });
   
    const res = await sendRequest(`assignOrders/${this.state._id}`,"PATCH",{
      logistic_id: this.state._id
    });
    if (res) {
      if (res.status === 200 || res.status === 201) {
        //this.props.deleteItemFromState(id);
      } else {
        console.log(res?.msg);
      }
    } else {
      console.log("Something went wrong while approving the user");
    }
    this.setState({isLoading:false});
    this.props.toggle();
  };

  componentDidMount() {
    // if item exists, populate the state with proper data
    if (this.props.item) {
      const {  amount,buyer_id,datePurchased,farmers,orderNumber,products,status,_id } =
        this.props.item;
      this.setState({  amount,buyer_id,datePurchased,farmers,orderNumber,products,status,_id, });
    }
  }

  render() {
    return (
      <>
       {this.state.isLoading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Submitting...</span>
          </Spinner>
          <p>This might take few seconds</p>
        </div>
      ) :
      (<Form onSubmit={this.assignDriver}>
        <div>
          <label>
            Select A Driver:
            <select
              name="driver"
              style={{ width: "100px", padding: 10, borderRadius: 10,width: "450px",margin: "10px 0px"}}
              value={this.state.driver === null ? "" : this.state.driver}
              onChange={this.onChange}
            >
              <option value="">-- Select --</option>
              <option value="option1">Driver 1</option>
              <option value="option2">Driver 2</option>
              <option value="option3">Driver 3</option>
            </select>
          </label>
        </div>
        <Button>Assign</Button>
      </Form>)}
      </>
    );
  }
}

export default AssignForm;
