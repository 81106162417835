// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getStorage} from 'firebase/storage'
const firebaseConfig = {
  apiKey: "AIzaSyDSS9MVF7LgVYt9QV-tSC2_KqIUX91Qoxk",
  authDomain: "efama-app.firebaseapp.com",
  projectId: "efama-app",
  storageBucket: "efama-app.appspot.com",
  messagingSenderId: "303979986651",
  appId: "1:303979986651:web:78e3f84bcd6e3e4e4f96fe"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);