//const BASE_URL = "https://efama-backend.cyclic.app/api/v1/admin";
//const BASE_URL = "https://efarm-backend.onrender.com/api/v1/admin";
//const BASE_URL = "https://efama-backend.cyclic.app/api/v1/admin";
//const BASE_URL_WITHOUT = "https://efama-backend.cyclic.app/api/v1";
//const BASE_URL_WITHOUT = "https://efarm-backend.onrender.com/api/v1";
// Base URLs with the new API Gateway endpoint

const BASE_URL = "https://cczxi37xtd.execute-api.af-south-1.amazonaws.com/prod/api/v1/admin";
const BASE_URL_WITHOUT = "https://cczxi37xtd.execute-api.af-south-1.amazonaws.com/prod/api/v1";

//const BASE_URL_WITHOUT = "http://13.244.52.30:10000/api/v1";
//const BASE_URL_WITHOUT = "http://ec2-13-244-52-30.af-south-1.compute.amazonaws.com:10000/api/v1";
//const BASE_URL = "http://ec2-13-244-52-30.af-south-1.compute.amazonaws.com:10000/api/v1/admin";
//const BASE_URL = "http://13.244.52.30:5000/api/v1/admin";

//const BASE_URL_WITHOUT = "https://efama.net/api/v1";
//const BASE_URL = "https://efama.net/api/v1/admin"
//const apiUrl  = "https://efama.net";
// Example API call
/*fetch(`${apiUrl}/api/v1/admin`)
  .then(response => response.json())
  .then(data => console.log('my ddata',data))
  .catch(error => console.error('Error:', error));*/

//const BASE_URL_WITHOUT = "https://efama.net/api/v1";
//const BASE_URL = "https://efama.net/api/v1/admin";
//const apiUrl = "https://efama.net";


//const BASE_URL_WITHOUT = `${process.env.REACT_APP_API_URL}/api/v1`;
//const BASE_URL = `${process.env.REACT_APP_API_URL}/api/v1/admin`;

/**
 * Sends an API request to the specified URL with the given method and data.
 * @param {string} url - The URL path to send the request to.
 * @param {string} method - The HTTP method (GET, POST, PUT, DELETE, etc.).
 * @param {Object} [data=null] - The data to send with the request (optional).
 * @param {boolean} [withoutAdmin=false] - Whether to use the base URL without the "/admin" path.
 * @returns {Promise<Object>} The response data from the API.
 * @throws Will throw an error if the request fails.
 */
export const sendRequest = async (url, method, data = null, withoutAdmin = false) => {
  const token = localStorage.getItem("token");
  let baseUrl = BASE_URL;
  if (withoutAdmin) {
    baseUrl = BASE_URL_WITHOUT;
  }

  const options = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : undefined, // Include token if available
    },
    body: data ? JSON.stringify(data) : null,
  };

  console.log('Requesting:', `${baseUrl}/${url}`, options); // Log request details for debugging

  try {
    const response = await fetch(`${baseUrl}/${url}`, options);
    const text = await response.text(); // Get the raw response text

    console.log('Response text:', text); // Log the response text for debugging

    if (!response.ok) {
      // Handle non-2xx responses
      console.error('Error response text:', text); // Log the raw response text
      let errorData;
      try {
        errorData = JSON.parse(text); // Try to parse as JSON
      } catch (e) {
        // If parsing fails, use the raw text as the error message
        errorData = { msg: text };
      }
      window.alert(errorData.msg || "Request failed!");
      throw new Error(errorData.msg || "Request failed!");
    }

    try {
      const responseData = JSON.parse(text); // Parse the raw text as JSON
      return responseData;
    } catch (e) {
      // Handle cases where the response is not valid JSON
      console.error('Failed to parse JSON:', e);
      console.error('Response text:', text); // Log the raw response text for debugging
      window.alert("Invalid JSON response!");
      throw new Error("Invalid JSON response!");
    }
  } catch (error) {
    console.error('Fetch error:', error);
    window.alert(error.message || "An unexpected error occurred!");
    throw error;
  }
};
