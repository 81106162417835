import DataTable from "Forms/products/DataTable";
import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { getProducts } from "services/GeneralServices";
// import { CSVLink } from "react-csv";
import { sendRequest } from "services/GeneralServices";

function Products() {
  useEffect(() => {
    setIsLoading(true);
    const fetchProducts = async () => {
      const products = await sendRequest("products","GET",null,true);
      if (products) {
        if (products.status === 200 || products.status === 201) {
          console.log("orders",products)
          setItems(products?.data);
        } else {
          setError(products?.msg);
          console.log(products?.msg);
          window.alert(products?.msg);
        }
      } else {
        // setError("An error occurred while pulling products");
        // window.alert("An error occurred while pulling products");
      }
      setIsLoading(false);
    };
    fetchProducts();
  }, []);

  const [items, setItems] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const updateState = (item) => {
    const itemIndex = items?.findIndex((data) => data.id === item.id);
    const newArray = [
      ...items.slice(0, itemIndex),
      item,
      ...items.slice(itemIndex + 1),
    ];
    setItems(newArray);
  };

  const deleteItemFromState = (id) => {

    const updatedItems = items.filter((item) => item._id !== id);
    setItems(updatedItems);
  };

  return (
    <>
    {isLoading ?  <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p>Loading...</p>
          </div> : 
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Products</Card.Title>
                <p className="card-category">All available Products</p>
                {/* <div className="d-flex justify-content-end">
                  <Button variant="secondary" className="mr-2">
                    Add a Product
                  </Button>
                  <Button variant="danger">Delete all Products</Button>{" "}
                </div> */}
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                {items?.length === 0 ? (
                  <Container className="text-center">
                    <p style={{ color: "gray" }}>
                      Your Order list is currently empty.
                    </p>
                  </Container>
                ) : (
                  <DataTable
                    items={items}
                    updateState={updateState}
                    deleteItemFromState={deleteItemFromState}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <CSVLink
              filename={"Products.csv"}
              color="primary"
              style={{ float: "left", marginRight: "10px" }}
              className="btn btn-primary"
              data={items}
            >
              Download CSV
            </CSVLink>
          </Col>
        </Row> */}
      </Container>}
    </>
  );
}

export default Products;
