import React, { Component } from "react";
import { Table, Button } from "reactstrap";
import ModalForm from "./Modal";
import { deleteProduct } from "services/GeneralServices";
import { sendRequest } from "services/GeneralServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class DataTable extends Component {
  deleteItem = async (id) => {
    let confirmDelete = window.confirm("Delete item forever?");
    if (confirmDelete) {
      //deleting the product from state
      this.props.deleteItemFromState(id);
      const res = await sendRequest(`products/${id}`, "DELETE");
      if (res) {
        if (res.status === 200 || res.status === 201) {
          this.props.deleteItemFromState(id);
        } else {
          console.log(res?.msg);
        }
      } else {
        console.log("Something went wrong while deleting a product");
      }
    }
  };

  render() {
    const items = this.props.items.map((item) => {
      return (
        <tr key={item?._id}>
          <td>{item?.name}</td>
          <td>{item?.category?.name}</td>
          <td>R{item?.price}</td>
          <td>{item?.quantity}</td>
          <td>{item?.province}</td>
          <td>
            {item?.owner?.firstname} {item?.owner?.lastname}
          </td>
          <td>
            {item?.owner?.phone}
          </td>
          <td>
            <div
              style={{
                width: "110px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <ModalForm
                buttonLabel="View"
                item={item}
                updateState={this.props.updateState}
              />{" "}
              {/* <Button color="danger" onClick={() => this.deleteItem(item?._id)}>
                Del
              </Button> */}
              <div onClick={() => this.deleteItem(item?._id)} >
                <FontAwesomeIcon color="grey"  icon="trash-can" />
              </div>
            </div>
          </td>
        </tr>
      );
    });

    return (
      <Table responsive hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Category</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Province</th>
            <th>Farmer</th>
            <th>Farmer Contacts</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{items}</tbody>
      </Table>
    );
  }
}

export default DataTable;
