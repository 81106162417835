import React from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { updateOrderStatus } from 'services/GeneralServices';
import Image from 'react-bootstrap/Image'
import { updateProductDetails } from 'services/GeneralServices';
import { sendRequest } from 'services/GeneralServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Spinner from "react-bootstrap/Spinner";

class ViewForm extends React.Component {
  state = {
    _id: 0,
    name: '',
    category: '',
    categoryId: '',
    description: '',
    photoDownloadURL: '',
    price: '',
    province: '',
    quantity:'',
    name:'',
    categoryObj:null,
    owner:null,
    isLoading:false
  }

  onChange = e => {
    this.setState({[e.target.name]: e.target.value})
  }



  componentDidMount(){
    // if item exists, populate the state with proper data
    if(this.props.item){
      const { _id,category,description,photoDownloadURL,price,province,quantity, name,owner } = this.props.item
      this.setState({ _id,categoryObj:category, name,categoryId:category?.id, category:category?.name, description, photoDownloadURL, price, province,quantity,owner })
    }
  }
  submitFormEdit = async(e) => {
    e.preventDefault()
    this.setState({isLoading:true});
    //update the state,this might lead to misunderstand when api failed.
    this.props.updateState({
      _id: this.state._id,
      name: this.state.name,
      category: this.state.categoryObj,
      description: this.state.description,
      photoDownloadURL: this.state.photoDownloadURL,
      price: this.state.price,
      province: this.state.province,
      quantity: this.state.quantity,
      owner:this.state.owner,
    })
    const updateProductRes = await sendRequest(`products/${this.state._id}`,"PATCH",{
        name: this.state.name,
        description: this.state.description,
        price: this.state.price,
        category: this.state.categoryId,
        isApproved: true
    })

      if(updateProductRes){
       if(updateProductRes.status === '201' || updateProductRes.status === '200'){
        this.props.updateState({
          _id: this.state.id,
          name: this.state.name,
          category: this.state.category,
          description: this.state.description,
          photoDownloadURL: this.state.photoDownloadURL,
          price: this.state.price,
          province: this.state.province,
          quantity: this.state.quantity,
          owner:this.state.owner,
        })
       }else{
        console.log(updateProductRes?.msg)
       }
      }else{
        console.log("Something went wrong while updating product")
      }
      this.setState({isLoading:false});
      this.props.toggle()
    }

  render() {
    return (
      <>
       {this.state.isLoading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Submitting...</span>
          </Spinner>
          <p>This might take few seconds</p>
        </div>
      ) :
      (<Form onSubmit={this.submitFormEdit}>
        <Image width={450} src={this.state?.photoDownloadURL} />
        <FormGroup>
          <Label for="name">Product Name</Label>
          <Input  type="text" name="name" id="name" onChange={this.onChange} value={this.state.name === null ? '' : this.state.name} />
        </FormGroup>
        <FormGroup>
          <Label for="category">Product Category</Label>
          <Input disabled type="text" name="category" id="category" onChange={this.onChange} value={this.state.category === null ? '' : this.state.category}  />
        </FormGroup>
        <FormGroup>
          <Label for="date">Product Description</Label>
          <Input  type="textarea" name="description" id="description" onChange={this.onChange} value={this.state.description === null ? '' : this.state.description}  />
        </FormGroup>
        <FormGroup>
          <Label for="amount">Price</Label>
          <Input  type="text" name="price" id="price" onChange={this.onChange} value={this.state.price === null ? '' : this.state.price}/>
        </FormGroup>
        <FormGroup>
          <Label for="amount">Province</Label>
          <Input  type="text" name="province" id="province" onChange={this.onChange} value={this.state.province === null ? '' : this.state.province}/>
        </FormGroup>
        <FormGroup>
          <Label for="amount">Quantity</Label>
          <Input  type="text" name="quantity" id="quantity" onChange={this.onChange} value={this.state.quantity === null ? '' : this.state.quantity}/>
        </FormGroup>
        <Button>Submit</Button>
      </Form>)}
      </>
    );
  }
}

export default ViewForm