import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";

import AdminLayout from "layouts/Admin.js";
import Login from "views/Login";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrashCan,faCirclePlus,faEye} from '@fortawesome/free-solid-svg-icons';

library.add(faTrashCan,faCirclePlus,faEye);

const token = localStorage.getItem("token");
console.log("token",token)
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      token ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Switch>
      <PrivateRoute path="/admin" component={AdminLayout} />
      <Route exact path="/login" render={(props) => <Login />} />
   <Redirect from="/" to="/admin/dashboard" /> 
    </Switch>
  </BrowserRouter>
);
