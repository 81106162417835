import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import DataTable from "Forms/orders/DataTable";
import { sendRequest } from "services/GeneralServices";

function Orders() {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setIsLoading(true);
    sendRequest('orders', 'GET')
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          setItems(response.data);
        } else {
          throw new Error(response.msg || "Failed to fetch orders");
        }
      })
      .catch(error => {
        console.error("Error fetching orders:", error);
        setError(error.message || "An unexpected error occurred");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p>Loading...</p>
        </div>
      ) : error ? (
        <Alert variant="danger" onClose={() => setError("")} dismissible>
          <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      ) : (
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">Orders</Card.Title>
                  <p className="card-category">List of all orders made</p>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  {items.length === 0 ? (
                    <Container className="text-center">
                      <p style={{ color: "gray" }}>
                        Your Order list is currently empty.
                      </p>
                    </Container>
                  ) : (
                    <DataTable
                      items={items}
                      updateState={(updatedItem) => {
                        const updatedItems = items.map(item =>
                          item.id === updatedItem.id ? updatedItem : item
                        );
                        setItems(updatedItems);
                      }}
                      deleteItemFromState={(id) => {
                        const updatedItems = items.filter(item => item.id !== id);
                        setItems(updatedItems);
                      }}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default Orders;
