import React, { useState } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { sendRequest } from "services/GeneralServices";
import Spinner from "react-bootstrap/Spinner";
import CustomAlert from "components/CustomAlert/CustomAlert";


const RegisterUser = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    phone: "",
    role: "",
    password: "",
    confirmpassword: "",
    type: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleCloseSuccess = () => {
    setShowAlert(false);
  };

  const submitFormEdit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const registrationDetails = await sendRequest(
      "signup",
      "POST",
      {
        firstname: formData.firstname,
        lastname: formData.lastname,
        email: formData.email,
        phone: formData.phone,
        buyerType: formData.type,
        username: formData.username,
        role: formData.role,
        password: formData.password,
        confirmPassword: formData.confirmpassword,
      },
      true
    );
    if (registrationDetails) {
      if (
        registrationDetails.status === 201 ||
        registrationDetails.status === 200
      ) {
        setShowAlert(true);
        setError(false);
        setMessage(registrationDetails?.msg);
        console.log("registered", registrationDetails.data);
      } else {
        setError(true);
        setShowAlert(true);
        setMessage(registrationDetails?.msg);
        window.alert(registrationDetails?.msg);
      }
    } else {
      setError(true);
      setMessage("Something went wrong");
      // setShowAlert(true);
      // window.alert("An error occurred,while trying to register a user");
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p>Loading...</p>
        </div>
      ) : (
        <div style={{ padding: 20 }}>
          {showAlert && (
            <CustomAlert
              variant={isError ? "danger" : "success"}
              heading="What's going on ?"
              message={message}
              onClose={handleCloseSuccess}
            />
          )}
          <Form onSubmit={submitFormEdit}>
            <FormGroup>
              <Label for="firstname">First Name</Label>
              <Input
                type="text"
                name="firstname"
                id="firstname"
                onChange={onChange}
                placeholder="e.g Sainty"
                value={formData.firstname}
              />
            </FormGroup>
            <FormGroup>
              <Label for="lastname">Last Name</Label>
              <Input
                type="text"
                name="lastname"
                id="lastname"
                onChange={onChange}
                placeholder="e.g Mpheroane"
                value={formData.lastname}
              />
            </FormGroup>
            <FormGroup>
              <Label for="username">Username</Label>
              <Input
                type="text"
                name="username"
                id="username"
                onChange={onChange}
                placeholder="e.g Lukeman"
                value={formData.username}
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                onChange={onChange}
                placeholder="e.g Sainty@efama.com"
                value={formData.email}
              />
            </FormGroup>
            <FormGroup>
              <Label for="phone">Phone</Label>
              <Input
                type="text"
                name="phone"
                id="phone"
                onChange={onChange}
                value={formData.phone}
                placeholder="e.g +27868403794"
              />
            </FormGroup>
            <FormGroup>
              <Label for="role">User Role</Label>
              <Input
                type="select"
                name="role"
                id="role"
                onChange={onChange}
                value={formData.role}
              >
                <option value="">-- Select --</option>
                <option value="buyer">buyer</option>
                <option value="farmer">farmer</option>
                <option value="admin">admin</option>
                <option value="logistics">logistics</option>
              </Input>
            </FormGroup>
            {formData.role === "buyer" && (
              <FormGroup>
                <Label for="type">Buyer Type</Label>
                <Input
                  type="select"
                  name="type"
                  id="type"
                  onChange={onChange}
                  value={formData.type}
                >
                  <option value="">-- Select --</option>
                  <option value="commercial">Commercial</option>
                  <option value="consumer">Consumer</option>
                </Input>
              </FormGroup>
            )}
            <FormGroup>
              <Label for="password">Password</Label>
              <Input
                type="password"
                name="password"
                id="password"
                onChange={onChange}
                value={formData.password}
              />
            </FormGroup>
            <FormGroup>
              <Label for="confirmpassword">Confirm Password</Label>
              <Input
                type="password"
                name="confirmpassword"
                id="confirmpassword"
                onChange={onChange}
                value={formData.confirmpassword}
              />
            </FormGroup>
            <Button color="primary">Register</Button>
          </Form>
        </div>
      )}
    </>
  );
};

export default RegisterUser;
