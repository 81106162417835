import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import ViewForm from "./ViewEditForm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ModalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  render() {
    const closeBtn = (
      <button className="close" onClick={this.toggle}>
        &times;
      </button>
    );

    const label = this.props.buttonLabel;

    let button = "";
    let title = "";

    if (label === "View") {
      button = (
        <div onClick={this.toggle}>
      <FontAwesomeIcon color="grey" icon="eye" /> 
      </div>

      );
      title = "Product Details";
    } else {
    }

    return (
      <div>
        {button}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle} close={closeBtn}>
            {title}
          </ModalHeader>
          <ModalBody>
          <ViewForm
                addItemToState={this.props.addItemToState}
                updateState={this.props.updateState}
                toggle={this.toggle}
                item={this.props.item}
              />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default ModalForm;
