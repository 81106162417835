import React, { useEffect } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { updateOrderStatus } from "services/GeneralServices";
import Image from "react-bootstrap/Image";
import { sendRequest } from "services/GeneralServices";
import Spinner from "react-bootstrap/Spinner";
class ViewForm extends React.Component {
  state = {
    amount: "",
    buyer_id: "",
    datePurchased: "",
    farmers: [],
    orderNumber: "",
    products: [],
    status: "",
    _id: "",
    buyer_Name: "",
    buyer_Contact: "",
    isLoading:false
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    // if item exists, populate the state with proper data
    if (this.props.item) {
      const {
        amount,
        buyer_id,
        datePurchased,
        farmers,
        orderNumber,
        products,
        status,
        _id,
        buyer_Contact,
        buyer_Name
      } = this.props.item;
      this.setState({
        amount,
        buyer_id,
        datePurchased,
        farmers,
        orderNumber,
        products,
        status,
        _id,
        buyer_Contact,
        buyer_Name
      });
    }
  }
  submitFormEdit = async (e) => {
    e.preventDefault();
    this.setState({isLoading:true});
    this.props.updateState({
      _id: this.state._id,
      amount: this.state.amount,
      buyer_id: this.state.buyer_id,
      datePurchased: this.state.datePurchased,
      orderNumber: this.state.orderNumber,
      status: this.state.status,
      address: this.state.address,
      driver: this.state.driver,
      products: this.state.products,
      farmers: this.state.farmers,
      buyerName: "Sainty",
      buyerContacts: "0767036727",
    });
    const res = await sendRequest(`orders/${this.state._id}`,"PATCH",{
      status: this.state.status
    });
    if (res) {
      if (res.status === 200 || res.status === 201) {
        //this.props.deleteItemFromState(id);
      } else {
        console.log(res?.msg);
      }
    } else {
      console.log("Something went wrong while approving the user");
    }
    this.setState({isLoading:false});
    this.props.toggle();
  };

  render() {
    return (
      <>
       {this.state.isLoading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Submitting...</span>
          </Spinner>
          <p>This might take few seconds</p>
        </div>
      ) :(
      <Form onSubmit={this.submitFormEdit}>
        {this.state.products.map((product, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Image
              width={100}
              src={product?.imageUrl}
              style={{ marginRight: "10px" }}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Label>Product Name: {product?.product_name}</Label>
              <Label>Product Quantity: {product?.quantity}</Label>
              {/* <Label>From farmer: 0767036273</Label> */}
            </div>
          </div>
        ))}

        <FormGroup>
          <Label for="name">Order Number</Label>
          <Input
            disabled
            type="text"
            name="orderNumber"
            id="orderNumber"
            onChange={this.onChange}
            value={
              this.state.orderNumber === null ? "" : this.state.orderNumber
            }
          />
        </FormGroup>
        <FormGroup>
          <Label for="date">Order Date</Label>
          <Input
            disabled
            type="text"
            name="date"
            id="date"
            onChange={this.onChange}
            value={
              this.state.datePurchased === null ? "" : this.state.datePurchased
            }
          />
        </FormGroup>
        <FormGroup>
          <Label for="amount">Total Amount</Label>
          <Input
            disabled
            type="text"
            name="amount"
            id="amount"
            onChange={this.onChange}
            value={this.state.amount === null ? "" : this.state.amount}
          />
        </FormGroup>
        <FormGroup>
          <Label for="amount">Buyer Name</Label>
          <Input
            disabled
            type="text"
            name="buyerName"
            id="buyerName"
            onChange={this.onChange}
            value={this.state.buyer_Name === null ? "" : this.state.buyer_Name}
          />
        </FormGroup>
        <FormGroup>
          <Label for="amount">Buyer Contacts</Label>
          <Input
            disabled
            type="text"
            name="buyerContacts"
            id="buyerContacts"
            onChange={this.onChange}
            value={
              this.state.buyer_Contact === null ? "" : this.state.buyer_Contact
            }
          />
        </FormGroup>
        <FormGroup>
          <Label for="address">Delivery Address</Label>
          <Input
            disabled
            type="text"
            name="address"
            id="address"
            onChange={this.onChange}
            value={this.state.address}
          />
        </FormGroup>
        <div>
          <label>
            Select Order Status:
            <select
              name="status"
              style={{
                width: "100px",
                padding: 10,
                borderRadius: 10,
                width: "450px",
                margin: "10px 0px",
              }}
              value={this.state.status === null ? "" : this.state.status}
              onChange={this.onChange}
            >
              <option value="">-- Select --</option>
              <option value="Pending">Pending</option>
              <option value="Processing">Processing</option>
              <option value="Shipped">Shipped</option>
              <option value="Delivered">Delivered</option>
              <option value="Cancelled">Cancelled</option>
              <option value="onHold">On Hold</option>
              <option value="Refunded">Refunded</option>
              <option value="Returned">Returned</option>
              <option value="Completed">Completed</option>
              <option value="Awaiting payment">Awaiting Payment</option>
            </select>
          </label>
        </div>
        <Button>Submit</Button>
      </Form>)}
      </>
    );
  }
}

export default ViewForm;
