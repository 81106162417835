import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

const CustomAlert = ({ variant, heading, message, onClose }) => {
  return (
    <Alert variant={variant}>
      <Alert.Heading>{heading}</Alert.Heading>
      <p>{message}</p>
      <hr />
      <div className="d-flex justify-content-end">
        <Button onClick={onClose} variant={`outline-${variant}`}>
          Close me!
        </Button>
      </div>
    </Alert>
  );
};

export default CustomAlert;
