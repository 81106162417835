
import React, { useState, useEffect } from "react";
import CustomAlert from "components/CustomAlert/CustomAlert";
import Spinner from "react-bootstrap/Spinner";

// react-bootstrap components
import { Card, Container, Row, Col, Pagination, Modal } from "react-bootstrap";
// import { CSVLink } from "react-csv";
import { sendRequest } from "services/GeneralServices";
import { getListOfBuyers } from "services/GeneralServices";
import Form from "react-bootstrap/Form";
import DataTable from "Forms/BuyerPending/DataTable";

function PendingBuyer() {
  useEffect(() => {
    const fetchBuyer = async () => {
      setIsLoading(true);
      const listOfBuyers = await sendRequest("buyers", "GET", null, true);
      if (listOfBuyers) {
        console.log(">>>", listOfBuyers);
        if (listOfBuyers.status === 200 || listOfBuyers.status === 201) {
          const activeBuyers = listOfBuyers?.data.filter((buyer) => buyer?.type === "commercial");
          setItems(activeBuyers);
          setMessage(listOfBuyers?.msg);
          setShowAlert(true);
        } else {
          setShowAlert(true);
          setError(true);
          window.alert(listOfBuyers?.msg);
        }
      } else {
        setShowAlert(true);
        // setError(true);
        // window.alert("An error occurred while pulling list Of Buyers");
      }
      setIsLoading(false);
    };
    fetchBuyer();
  }, []);
  const [items, setItems] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //Manage Items

  const addItemsToState = (item) => {
    setItems([...items, item]);
  };

  const updateState = (item) => {
    console.log(">>item", item);
    const itemIndex = items?.findIndex((data) => data._id === item._id);
    const newArray = [
      ...items.slice(0, itemIndex),
      item,
      ...items.slice(itemIndex + 1),
    ];
    setItems(newArray);
  };

  const deleteItemFromState = (id) => {
    const updatedItems = items.filter((item) => item._id !== id);
    setItems(updatedItems);
  };

  return (
    <>
      {isLoading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p>Loading...</p>
        </div>
      ) : (
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">User's account Management</Card.Title>
                  <p className="card-category">List of all commercial Buyers</p>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  {items.length === 0 ? (
                    <Container className="text-center">
                      <p style={{ color: "gray" }}>
                        Your list is currently empty.
                      </p>
                    </Container>
                  ) : (
                    <DataTable
                      items={items}
                      updateState={updateState}
                      deleteItemFromState={deleteItemFromState}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <CSVLink
                filename={"ActiveBuyers.csv"}
                color="primary"
                style={{ float: "left", marginRight: "10px" }}
                className="btn btn-primary"
                data={items}
              >
                Download CSV
              </CSVLink>
               <ModalForm
              buttonLabel="Add Item"
              addItemToState={addItemsToState}
            /> 
            </Col>
          </Row> */}
        </Container>
      )}
    </>
  );
}

export default PendingBuyer;
