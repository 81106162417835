import Dashboard from "views/Dashboard.js";
import Orders from "views/Orders.js";
import Products from "views/Products.js";
import ApprovedBuyer from "views/ApprovedBuyer.js";
import ApprovedFarmers from "views/ApprovedFarmers";
import PendingFarmers from "views/PendingFarmers.js";
import PendingBuyer from "views/PendingBuyer.js";
import Upgrade from "views/Upgrade.js";
import RegisterUser from "views/RegisterUsers";

const dashboardRoutes = [
  {
    upgrade: true,
    path: "/upgrade",
    name: "efama @2023",
    icon: "nc-icon nc-alien-33",
    component: Upgrade,
    layout: "/admin",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/user",
    name: "Orders",
    icon: "nc-icon nc-delivery-fast",
    component: Orders,
    layout: "/admin",
  },
  {
    path: "/table",
    name: "Products",
    icon: "nc-icon nc-apple",
    component: Products,
    layout: "/admin",
  },
  {
    path: "/typography",
    name: "Consumer",
    icon: "nc-icon nc-circle-09",
    component: ApprovedBuyer,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Commercial",
    icon: "nc-icon nc-circle-09",
    component: PendingBuyer,
    layout: "/admin",
  },
  {
    path: "/icons",
    name: "Farmers (Approved)",
    icon: "nc-icon nc-circle-09",
    component: ApprovedFarmers,
    layout: "/admin",
  },
  {
    path: "/maps",
    name: "Farmers (Pending)",
    icon: "nc-icon nc-circle-09",
    component: PendingFarmers,
    layout: "/admin",
  },
  {
    path: "/register",
    name: "Register Users",
    icon: "nc-icon nc-circle-09",
    component: RegisterUser,
    layout: "/admin",
  },
];

export default dashboardRoutes;
