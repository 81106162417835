import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import ViewForm from "./ViewForm";
import AssignForm from "./AssignDriver";
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ModalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  render() {
    const closeBtn = (
      <button className="close" onClick={this.toggle}>
        &times;
      </button>
    );

    const label = this.props.buttonLabel;

    let button = "";
    let title = "";

    

    if (label === "View") {
      button = (
        <div onClick={this.toggle} >
        <FontAwesomeIcon color="grey"  icon="eye" />
      </div>
        // <Button
        //   color="warning"
        //   onClick={this.toggle}
        //   style={{ float: "left", marginRight: "10px" }}
        // >
        //   {label}
        // </Button>
      );
      title = "Order Details for :";
    } else {
      button = (
        <div onClick={this.toggle} >
        <FontAwesomeIcon color="grey"  icon="circle-plus" />
      </div>
        // <Button
        //   color="success"
        //   onClick={this.toggle}
        //   style={{ float: "left", marginRight: "10px" }}
        // >
        //   {label}
        // </Button>
      );
      title = "Assign this Order to a Driver";
    }

    return (
      <div>
        {button}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle} close={closeBtn}>
            {title}
          </ModalHeader>
          <ModalBody>
            {label === "View" ? (
              <ViewForm
                addItemToState={this.props.addItemToState}
                updateState={this.props.updateState}
                toggle={this.toggle}
                item={this.props.item}
              />
            ) : (
              <AssignForm
                addItemToState={this.props.addItemToState}
                updateState={this.props.updateState}
                toggle={this.toggle}
                item={this.props.item}
              />
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default ModalForm;
