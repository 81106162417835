
import React, { useEffect, useState } from "react";
import CustomAlert from "components/CustomAlert/CustomAlert";
import Spinner from "react-bootstrap/Spinner";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { sendRequest } from "services/GeneralServices";
import { getListOfFarmers } from "services/GeneralServices";
import DataTable from "Forms/FarmerPending/DataTable";

function PendingFarmers() {
  useEffect(() => {
    const fetchFarmers = async () => {
      setIsLoading(true);
      const listOfFarmers = await sendRequest("farmers", "GET", null, true);
      if (listOfFarmers) {
        if (listOfFarmers.status === 200 || listOfFarmers.status === 201) {
          const pendingFarmers = listOfFarmers?.data.filter((farmer) => Boolean(farmer?.user?.isVerified) !== true);
          setItems(pendingFarmers);
          setMessage(listOfFarmers?.msg);
          setShowAlert(true);
        } else {
          setShowAlert(true);
          setError(true);
          window.alert(listOfFarmers?.msg);
        }
      } else {
        setShowAlert(true);
        // setError(true);
        // window.alert("An error occurred while pulling list Of Farmers");
      }
      setIsLoading(false);
    };
    fetchFarmers();
  }, []);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const addItemsToState = (item) => {
    setItems([...items, item]);
  };

  const updateState = (item) => {
    const itemIndex = items?.findIndex((data) => data._id === item._id);
    const newArray = [
      ...items.slice(0, itemIndex),
      item,
      ...items.slice(itemIndex + 1),
    ];
    setItems(newArray);
  };

  const deleteItemFromState = (id) => {
    const updatedItems = items.filter((item) => item._id !== id);
    setItems(updatedItems);
  };
  return (
    <>
      {
        isLoading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p>Loading...</p>
          </div>) :
        (<Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Farmer's account Management</Card.Title>
                <p className="card-category">List of all Farmers awaiting approval</p>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                {items.length === 0 ? (
                  <Container className="text-center">
                    <p style={{ color: "gray" }}>
                      Your list is currently empty.
                    </p>
                  </Container>
                ) : (
                  <DataTable
                    items={items}
                    updateState={updateState}
                    deleteItemFromState={deleteItemFromState}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>)}
    </>
  );
}

export default PendingFarmers;
