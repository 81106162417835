import React, { useState } from "react";
import "../index.css";
import { useHistory } from "react-router-dom";
import { adminLogin } from "services/GeneralServices";
import { css } from "@emotion/react";
import { BeatLoader } from "react-spinners";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { sendRequest } from "services/GeneralServices";

export default function (props) {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const override = css`
    margin: 0 auto;
    border-color: red;
    alignself: "center";
  `;
  const openErrorModal = (errorMessage) => {
    setError(errorMessage);
  };

  const closeErrorModal = () => {
    setError(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const authDetails = await sendRequest("login","POST",{
      email: email,
      password: password,
    });
    if (authDetails) {
      if (authDetails.status === 201 || authDetails.status === 200) {
       window.open("/admin/dashboard")
       console.log(">>>>",authDetails)
        await localStorage.setItem("token", authDetails?.data);
       
        console.log("HHHH",localStorage.getItem("token"))
      } else {
        //window.alert(authDetails?.msg);
      }
    } else {
      //window.alert("An error occurred,while trying to login");
    }
    setLoading(false);
  };

  return (
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={handleSubmit}>
        <div className="Auth-form-content">
          <Modal size="md" show={error !== null} onHide={closeErrorModal}>
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button onClick={closeErrorModal} variant="danger">
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="loader">
            <BeatLoader
              color={"#008037"}
              loading={loading}
              css={override}
              size={10}
            />
          </div>
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="form-group mt-3">
            <label>Email address</label>
            <input
              type="email"
              className="form-control mt-1"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button
              disabled={loading}
              type="submit"
              className="btn btn-primary"
            >
              Submit
            </button>
          </div>
          <p className="forgot-password text-right mt-2">
            Efama <a href="https://www.efama.africa/">stay logged in</a>
          </p>
        </div>
      </form>
    </div>
  );
}
